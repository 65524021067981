// import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SlideShow from "../components/slider"
import Blurb from "../components/blurb"
import Header from "../components/header"
import Seo from "../components/seo"
import { Row, Container } from "react-bootstrap"
import CardBody from "../components/bodyCards"

export const query = graphql`
  {
    theftLogo: file(relativePath: { eq: "anti-theft-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jacker: file(relativePath: { eq: "jacker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    crowBar: file(relativePath: { eq: "crowbar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carGone: file(relativePath: { eq: "car-gone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jackerPlier: file(relativePath: { eq: "jacker-pliers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jackerPry: file(relativePath: { eq: "jacker-pry.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AntiTheft = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const crowBar = data.crowBar.childImageSharp.fluid
  const theftLogo = data.theftLogo.childImageSharp.fluid
  const hotWire = data.jackerPry.childImageSharp.fluid
  const carGone = data.carGone.childImageSharp.fluid

  const content = [
    {
      title: "Identification Code",
      description: "Non-removable, traceable identification.",
      image: `${crowBar.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Body Panel Etching",
      description: "Identification code etched on body panels.",
      image: `${hotWire.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Theft Recovery",
      description: "Aids in the recovery of stolen vehicles.",
      image: `${carGone.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
  ]

  const text =
    "According to Statistics Canada, motor vehicle theft is one of the most common police reported offences in Canada. Automobile theft cost Canadians approximately $1 Billion a year, including cost for insurers to fix or replace stolen cars, as well as millions for police, health-care, court system and correctional services. Secure Ride Anti-Theft Protection can alleviate these costs and worries."
  const cardContent = {
    1: {
      img: data.jacker.childImageSharp.fluid,
      alt: "Car Thief",
      list: [
        "Thieves recognize your vehicle is traceable",
        "Identification makes a vehicle harder to sell to chop-shops",
        "Costs thieves $1,000's of dollars to replace etched parts",
        "Thieves will move on to steal a non-marked vehicle",
      ],
      header: "Why do thieves avoid vehicles with Secure Ride: Anti-Theft?",
    },
    2: {
      img: data.jackerPlier.childImageSharp.fluid,
      alt: "Thief Uses Pliers",
      list: [
        "No monitoring fees",
        "Cannot be disarmed, disabled or disconnected",
        "Creates a direct link between vehicle, dealership and owner",
        "Prevents theft and assists in recovery",
      ],
      header: "Why is body etching better than other security systems?",
    },
    3: {
      img: data.jackerPry.childImageSharp.fluid,
      alt: "Car Stolen",
      list: [
        "A non-removable, police traceable code",
        "Imprinted on select body panels",
        "The Code is registered in a national database",
        "The national database cross-references the vehicle's VIN, Dealership and Owner",
      ],
      header: "What is an Identification Code?",
    },
    4: {
      img: data.carGone.childImageSharp.fluid,
      alt: "Car Jacker",
      list: [
        "$5,000 towards a replacement vehicle if your vehicle is deemed a total loss",
        "If deemed a total loss, you will receive a rental vehicle and/or accommodation",
        "If your vehicle is recovered you get up to $1,000 towards your deductible",
        "If claiming stolen property up to $1,000 home insurance deductible",
      ],
      header: "What happens if your car is stolen?",
    },
  }

  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "anti-theft" }}
    >
      <Seo
        title="Anti-Theft"
        keywords={[
          `protection`,
          `vehicle`,
          `secure`,
          `safe`,
          `anti-theft`,
          `etching`,
          `labeling`,
          `prevention`,
          `theft`,
          `car`,
          `truck`,
          `suv`,
          `van`,
        ]}
      />
      <SlideShow data={content} />
      <Header logo={theftLogo.src} />
      <Blurb text={text} />
      <Container fluid>
        <Row>
          <CardBody content={cardContent} />
        </Row>
      </Container>
    </Layout>
  )
}

export default AntiTheft
